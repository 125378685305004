// export const BASE_URL = "https://newmerchhq.premiumfreshfoods.com/crmapi/"; // live
// export const BASE_URL = "https://script.merchhq.io/user/"; // develop
// export const BASE_URL = "http://192.168.1.20/merchhq/public/user/"; // local pushpa
// export const BASE_URL = "http://192.168.1.20/new_laravel_mhq/public/user/"; // local mukesh
export const BASE_URL = "https://backend-final.merchhq.io/user/";

export const URL = {
  // LOGIN
  LOGIN: "user-login",
  REGISTER: "user-register",
  FORGOT_PASSWORD: "forgot-password",
  VERIFY_PASSWORD: "verify-user",
  RESET_PASSWORD: "reset-password",
  USER_EDIT: "edit-user",
  VERIFY_TOKEN: "verify-user",
  USER_BANNER: "site-setting-list",
  TESTIMONIAL: "testimonial-list",
  BLOG_LIST: "blog-list",
  BLOG_VIEW: "blog-show/",
  BLOG_TAGS: "blog-tags",
  CATEGORIES: "category-list",
  PRODUCTLIST: "product-list",
  PRODUCT_VIEW: "product-view/",

  // ADDRESS
  ADDRESS_LIST: "list-user-address",
  ADDRESS_ADD: "add-user-address",
  ADDRESS_VIEW: "view-user-address/",
  ADDRESS_EDIT: "edit-user-address/",
  ADDRESS_DELETE: "delete-user-address/",

  //EVENT_CATEGORY
  EVENT_List: "event-category-list",
  PRESET_PACK: "preset-pack-list",
  PRESET_PACK_VIEW: "preset-pack-view/",
  PRODUCT_PRICE_LIST: "product-price-list",
  ADD_TO_CART: "add-to-cart",
  UPDATE_TO_CART: "update-cart",
  CART_LIST: "user-cart-list",
  DELETE: "delete-to-cart",
  PACK_UPDATE: "cart-pack-update",
  PRODUCT_REQUEST: "project-request",
  DASHBOARD_ORDER_LIST: "orders-list",
  TAGS_FILTER: "tags",
  COLOR_LIST: "products-color-list",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const options = {
  loop: true,
  center: false,
  items: 3,
  autoplay: true,
  dots: false,
  autoplayTimeout: 5000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 2,
      margin: 5,
    },
    576: {
      items: 2,
      margin: 5,
    },
    767: {
      items: 4,
      margin: 10,
    },
    1349: {
      items: 4,
      margin: 10,
    },
    2000: {
      items: 5,
      margin: 10,
    },
  },
};

export const productDetailImg = {
  loop: true,
  autoplay: true,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
export const dashboardBanner = {
  loop: true,
  autoplay: true,
  center: true,
  dots: false,
  autoplayTimeout: 4000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};

export const row2 = {
  loop: true,
  autoplay: true,
  center: false,
  dots: false,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,

  responsive: {
    0: {
      items: 4,
      margin: 10,
    },
    576: {
      items: 4,
      margin: 15,
    },
    776: {
      items: 5,
      margin: 15,
    },
    991: {
      items: 6,
      margin: 15,
    },
    1199: {
      items: 7,
    },
    1600: {
      items: 9,
    },
  },
};

export const row3 = {
  loop: false,
  autoplay: false,
  center: false,
  dots: false,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,

  responsive: {
    0: {
      items: 4,
      margin: 10,
    },
    576: {
      items: 4,
      margin: 15,
    },
    776: {
      items: 5,
      margin: 15,
    },
    991: {
      items: 6,
      margin: 15,
    },
    1199: {
      items: 7,
    },
    1600: {
      items: 9,
    },
  },
};
