// @ts-nocheck
import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { closeIcon, logist, product1, ship1, ship2 } from "../assets/img";
import RequiredSummary from "../component/Order/RequiredSummary";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import UpdateQuantity from "../component/MyProductDetail/UpdateQuantity";
import SelectRecipients from "../component/SelectProduct/SelectRecipients";
import SelectProduct from "../component/SelectProduct/SelectProduct";
import ProductsAndQuantity from "../component/SelectProduct/ProductsAndQuantity";
import DoneIcon from "@mui/icons-material/Done";
import AddExistingRecipient from "../component/SelectProduct/AddExistingRecipient";
import NewContact from "../component/SelectProduct/NewContact";
import Modal from "react-bootstrap/Modal";
import AddressPopup from "../component/Popup/AddressPopup";

const SelectProductScreen = () => {
  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("Add New");

  const [selectRec, setSelectRec] = useState(false);
  const [selectSingle, setSelectSingle] = useState(false);
  const [enableReci, setEnableReci] = useState(false);
  const [addReciPopup, setAddReciPopup] = useState(false);

  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(false);
    setShowContact(false);
  };

  const poppupAddressToggle = () => {
    setShowType("Add New");
    setShow(false);
  };

  const poppupAddressEditToggle = () => {
    setShowType("Edit");
    setShow(true);
  };

  return (
    <div className="tl-shipment-screen">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
        <div className="product-shipment d-flex flex-column flex-lg-row justify-content-between mt-3 mb-5">
          <div className="">
            <h4 className="mb-0 f4 fs-25 fs-sm-30 fs-lg-35 fs-xl-40">
              Shipping Address
            </h4>
          </div>
        </div>

        <SelectRecipients
          addReciPopup={addReciPopup}
          setAddReciPopup={setAddReciPopup}
          setShow={setShow}
          poppupAddressEditToggle={poppupAddressEditToggle}
        />
      </div>

      <ProductsAndQuantity
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
      />

      <AddExistingRecipient
        showUpdate={showUpdate}
        showClickHandler={showClickHandler}
      />
      <NewContact
        showUpdate={showContact}
        showClickHandler={showClickHandler}
        setShow={setShow}
      />
      {addReciPopup && (
        <div className="custPopup p-3 rounded-4">
          <button
            className="cust-btn"
            onClick={() => {
              setShowUpdate(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Select from Existing</h6>
          </button>
          <button
            className="cust-btn mt-2"
            onClick={() => {
              setShowContact(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Add New Contact</h6>
          </button>
        </div>
      )}

      {show && (
        <AddressPopup
          poppupAddressToggle={poppupAddressToggle}
          showType={showType}
        />
      )}
    </div>
  );
};

export default SelectProductScreen;
