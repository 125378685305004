import React, { useState } from "react";
import { Range } from "react-range";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
const RangeSet = ({ values, setValues }) => {
  const STEP = 0.1;
  const MIN = 0;
  const MAX = 30000;

  // const [values, setValues] = useState([5, 1000]);

  const onChange = (newValues) => {
    setValues(newValues);
  };
  // console.log(values);
  return (
    <div className="d-flex ac-jc  position-absolute">
      <div className="rangepopup px-4">
        <h6>Price Range</h6>
        <div className=" d-flex ac-jb">
          <output className="mb-1 ">₹{values[0].toFixed(1)}</output>
          <output className="mb-1 ">₹{values[1].toFixed(1)}</output>
        </div>
        <RangeSlider
          // step={1}
          // defaultValue={[5, 1000]}
          // thumbsDisabled={[true, false]}
          // rangeSlideDisabled={true}
          min={0}
          max={30000}
          value={values}
          onInput={setValues}
        />
        {/* <Range
          step={STEP}
          min={MIN}
          max={MAX}
          values={values}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '200px',
                backgroundColor: '#ccc',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                backgroundColor: '#839DD1',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC',
                }}
              />
            </div>
          )}
        /> */}
      </div>
    </div>
  );
};

export default RangeSet;
