// @ts-nocheck
import { useState } from "react";
import { banner, editIcon, searchIcon, select } from "../../assets/img";
const SelectRecipients = ({
  addReciPopup,
  setAddReciPopup,
  poppupAddressEditToggle,
  setShow,
}) => {
  const [checkbox, setCheckbox] = useState(null);

  const checkboxHandler = (value) => {
    if (checkbox == value) {
      setCheckbox(null);
    } else {
      setCheckbox(value);
    }
  };
  return (
    <div className="addReciView">
      {/* <div className=" addrecipient">
        <img src={select} className="bannerImge mt-3" />
      </div>
      <h4 class=" f4 mt-3 addrecipient">You don't have any saved recipients</h4>
      <p className=" fs-xs-11 addrecipient1">
        Add recipients to start creating shipments
      </p>
      <div className=" addrecipient">
        <button
          onClick={() => setAddReciPopup(!addReciPopup)}
          className=" cust-btn w-100 p-3 bg-lt-blue rounded rounded-4"
        >
          <h4 className="f4 primary fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
            Add Recipients
          </h4>
        </button>
      </div> */}
      <div className="btm-scroll pt-3 d-flex flex-column flex-md-row justify-content-between">
        <div className="position-relative d-flex ac-jc search-icon mb-2">
          <img src={searchIcon} alt="" className="searchImgIcon" />
          <input
            type="text"
            className="bg-transparent border-0 ps-1 f2 fs-6 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
            placeholder="Search"
            name=""
            id=""
          />
        </div>
        <div className="filterView">
          <p className="fs-xs-11 filterDesign">Filters</p>
          <select className="cust-btn seletDesign"></select>
        </div>
        <div className="filterView">
          <p className="fs-xs-11 filterDesign">Sort by</p>
          <select className="cust-btn seletDesign"></select>
        </div>
        <button className="cust-btn filterView1" onClick={() => setShow(true)}>
          <h4 className="f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
            +Add Recipients
          </h4>
        </button>
      </div>
      <div
        style={{ width: "100%", overflow: "auto" }}
        className="w-100 pt-3 w-100 mt-5"
      >
        <table className="table-design-new">
          <thead>
            <tr className="text-nowrap text-center">
              <th>S.No</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email ID</th>
              <th>Address Line 1</th>
              <th>Address Line 2</th>
              <th>Land Mark</th>
              <th>State</th>
              <th>City</th>
              <th>Pincode</th>
              <th>Phone Number</th>
              <th>Alternate Phone Number</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                <div className="d-flex align-items-center gap-2">
                  <div>1</div>
                  <input
                    type={"checkbox"}
                    onClick={() => checkboxHandler(1)}
                    checked={checkbox == 1}
                  />
                </div>
              </td>
              <td>Roger</td>
              <td>Daniel</td>
              <td>roger@companystore.io</td>
              <td>#39 Krishna Reddy Colony</td>
              <td>Domlur Layout</td>
              <td>Accord Road</td>
              <td>Karnataka</td>
              <td>Bangalore</td>
              <td>560071</td>
              <td>9632950798</td>
              <td>9632208028</td>
              <td>
                {" "}
                <div
                  className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                  onClick={() => poppupAddressEditToggle()}
                >
                  <img src={editIcon} className="viewDes" />
                </div>
              </td>
            </tr>
            <tr className="text-center">
              <td>
                <div className="d-flex align-items-center gap-2">
                  <div>2</div>
                  <input
                    type={"checkbox"}
                    onClick={() => checkboxHandler(2)}
                    checked={checkbox == 2}
                  />
                </div>
              </td>
              <td>Sakthi</td>
              <td>Vel</td>
              <td>vel@companystore.io</td>
              <td>#49 Krishna Reddy Colony</td>
              <td>Domlur Layout</td>
              <td>Accord Road</td>
              <td>Chennai</td>
              <td>Tamil Nadu</td>
              <td>600071</td>
              <td>9632950799</td>
              <td>9632208029</td>
              <td>
                {" "}
                <div
                  className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                  onClick={() => poppupAddressEditToggle()}
                >
                  <img src={editIcon} className="viewDes" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SelectRecipients;
