import React, { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import "../Build/FullProducts.css";
import {
  accesories,
  backpack,
  caps,
  discount,
  discountIcon,
  events,
  fitness,
  gadgets,
  home,
  hoodies,
  jackets,
  kit,
  listIcon,
  notebooks,
  packaging,
  poloShirt,
  shift,
  snakables,
  SpecialCateg,
  totes,
  travel,
  tshirts,
  tumbler,
  waterBottle,
} from "../../../assets/img";
import { useCategoryListMutation } from "../../../redux/api/api";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { Searcher, product1, productd, ticker } from "../../../assets/img";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RangeSet from "../../Range/RangeSet";
import { ArrowDropDown } from "@mui/icons-material";
// import Scrollspy from "react-scrollspy";
import "bootstrap/dist/css/bootstrap.min.css";

const PresetFullProduct = ({
  tick,
  categoryList,
  showPopupHander,
  addProductHandler,
  setViewId,
  onClickProduct,
  getAddToCart,
  getProductView,
  cartData,
  deleteCart,
  userCartID,
}) => {
  const [categShow, setCategShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [color, setColor] = useState(false);
  const [search, setSearch] = useState("");
  const [drag, setDrag] = useState(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const HandledDrag = () => {
    setDrag(!drag);
  };
  const ColorDropdown = () => {
    setColor(!color);
  };
  const HandledSearch = (e) => {
    const SearchTerm = e.target.value;
    setSearch(SearchTerm);
  };
  const categShowHandler = () => {
    setCategShow(!categShow);
  };

  const [activeSection, setActiveSection] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY + 100;

      sections.forEach((section) => {
        if (
          section.offsetTop <= scrollPosition &&
          section.offsetTop + section.offsetHeight > scrollPosition
        ) {
          setActiveSection(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    // setSidebarOpen(false);
  };

  // console.log(activeSection, "activeSection");
  // console.log(activeSection, "activeSection");

  return (
    <>
      <div
        className={`${
          categShow ? "left-product-show" : "left-product-hid"
        } left-product `}
      >
        <button onClick={categShowHandler} className="cat-title ps-4 cust-btn">
          <ListIcon />
          <p>Categories</p>
        </button>

        <div className="d-flex align-items-center justify-content-lg-center ms-3 ">
          <div>
            {categoryList?.map((section, ind) => {
              // console.log(
              //   activeSection == section?.id,
              //   activeSection,
              //   section?.id
              // );
              return (
                <li
                  className="d-flex align-items-center list-box"
                  key={section?.id}
                >
                  <div className=" border-0 img-back  d-flex ac-jc my-1">
                    <img src={section?.icon_url} alt="" />
                  </div>
                  <a
                    className={`mb-0 ms-3 f3  ${
                      +activeSection === section?.id ? "activelist" : ""
                    }`}
                    href={`#${section?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(section?.id);
                    }}
                  >
                    {section?.name}
                  </a>
                </li>
              );
            })}
          </div>
        </div>
      </div>
      <div className="withoutcart rit-prod bulk-prod mt-3">
        <h4 className="sect-title build mb-2 mb-lg-4">Design Your Kit</h4>
        <div className="d-flex align-items-center justify-content-end position-relative">
          <FilterAltIcon
            onClick={toggleFilter}
            className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 pack-title"
          />
          {showFilter && (
            <div className="filter-options position-absolute ">
              <p>Price</p>
              <div>
                <p onClick={toggleFilter} className="active">
                  Low to High
                </p>
                <p onClick={toggleFilter}>High to Low</p>
              </div>
            </div>
          )}
        </div>

        {categoryList?.map((item, ind) => {
          return (
            item?.products?.length > 0 && (
              <section id={item?.id} key={ind}>
                <h5 className="pack-title mb-0 mb-lg-3">{item?.title}</h5>
                <p className="parag disc w-90">{item?.description}</p>

                <div className="d-flex flex-wrap w-100 ac-je">
                  <div className="d-flex flex-wrap ps-4 w-100 ac-js">
                    {item?.products?.map((product, inx) => {
                      return (
                        <div
                          className="p-2 p-md-3 p-xxl-4 prod-list-box-tl"
                          key={inx}
                        >
                          <div className="prod-list-box">
                            <button
                              // onClick=>{showPopupHander,setViewId(product?.id)}
                              onClick={() => {
                                // showPopupHander();
                                getProductView(product?.id);
                              }}
                              className="cust-btn prod-list-box-img"
                            >
                              <img
                                src={
                                  product?.defaultImage
                                    ? product?.defaultImage
                                    : product1
                                }
                                alt=""
                              />
                            </button>
                            <div className="btm-prod">
                              <h3>{product?.name}</h3>
                              <div className="d-flex justify-content-between">
                                <p className=" f2">
                                  Starting at {+product?.for_25}
                                </p>
                                <button
                                  onClick={() => {
                                    // addProductHandler(item, ind, product, inx);
                                    if (cartData?.[product?.id]?.cart_id) {
                                      deleteCart(
                                        cartData?.[product?.id]?.cart_id,
                                        product?.id
                                      );
                                    } else {
                                      onClickProduct(product?.id);
                                    }

                                    // getAddToCart(product?.id, product?.for_100);
                                  }}
                                  className="plus"
                                >
                                  {tick?.includes(product?.id) ? (
                                    <CheckIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )
          );
        })}
      </div>
    </>
  );
};

export default PresetFullProduct;
