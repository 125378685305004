// @ts-nocheck
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { build_Pack, bulkPack, presetPack } from "../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const LoginMenuItem = ({
  showMenu,
  showdropList,
  setShowdropList,
  setSignShowdropList,
  setShowMenu,
  companyDropdown,
  setCompanyDropdown,
  plotformMenu,
  setPlotformMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);
  const onClickPageNavigate = (e) => {
    if (e == "build") {
      navigate("products/build-pack");
    } else if (e == "preset") {
      navigate("products/preset-pack");
    } else if (e == "bulk") {
      navigate("products/bulk-pack");
    }
    setShowdropList(false);
    setShowMenu(false);
    setCompanyDropdown(false);
    setPlotformMenu(false);
  };
  const onClickPlotformPageNavigate = (e) => {
    if (e == "membership") {
      navigate("services/membership");
    } else if (e == "redeem") {
      navigate("services/redeem");
    }
    console.log("ssss");
    setShowdropList(false);
    setCompanyDropdown(false);
    setShowMenu(false);
    setPlotformMenu(false);
  };
  const onClickCompanyPageNavigate = (e) => {
    if (e == "about-us") {
      navigate("company/about-us");
    } else if (e == "blog") {
      navigate("company/blog");
    } else if (e == "faq") {
      navigate("company/faq");
    }
    setCompanyDropdown(false);
    setShowMenu(false);
    setPlotformMenu(false);
  };
  return (
    <div
      className="menu-item d-md-flex"
      style={{
        right: showMenu ? "0px" : "-100%",
        visibility: showMenu ? "visible" : "hindden",
      }}
    >
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <p
          className={`${showMenu ? "d-flex" : "d-none"} closebtn`}
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <CloseIcon />
        </p>
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/dashboard");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Dashboard
        </button>
        {splitLocation[1] === "dashboard" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-products");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Products
        </button>
        {splitLocation[1] === "my-products" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-order");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Orders
        </button>
        {splitLocation[1] === "my-order" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-inventory");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Inventory
        </button>
        {splitLocation[1] === "my-inventory" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/shipment-home");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Shipments
        </button>
        {splitLocation[1] === "shipment-home" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/redeem");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Redeem
        </button>
        {splitLocation[1] === "pricing" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            // navigate("/pricing");
            navigate("/integrations");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Integrations
        </button>
        {splitLocation[1] === "integrations" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>

    </div>
  );
};

export default LoginMenuItem;
