import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { discountIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const ProductsAndQuantity = ({ OpenSummery, showClickHandler }) => {
  const navi = useNavigate();
  return (
    <div
      className="req-summary"
      style={{ right: OpenSummery ? "0px" : "-400px" }}
    >
      <h4 className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-20 ms-2 primary">
        Products and Quantity
      </h4>
      <div className="d-flex mt-4">
        <div className="box-shadow-product1">
          <img
            src={require("../../assets/img/product_detail_img.png")}
            className="productImg1"
          />
        </div>
        <div>
          <h4 className="ms-3 mb-0 f4 fs-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 text-dark">
            Next Level Crew Tee
          </h4>
          <div className="d-flex ac-jb">
            <h6 className="ms-3 mb-0 f1 fs-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-5 minText">
              Qty : 0
            </h6>

            <select className="cust-btn w-35 mb-2">
              <option className="">100</option>
            </select>
          </div>
          {/* <h4 className="ms-3 mb-0 f1 fs-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-5 text-dark">
            Quantity per pack : 1
          </h4> */}
        </div>
      </div>
      <div className="alignQuantity">
        <div className="d-flex ac-jb">
          <div className="d-flex w-70 ac-jb">
            <h6 className="text-black fs-xs-10">Quantity</h6>
            <h6 className=" fs-xs-10 minText"> (Minimun 25)</h6>
          </div>

          <select className="cust-btn w-25 mb-2">
            <option className="">100</option>
          </select>
        </div>

        <div className="d-flex w-100 ac-jb memb-dics">
          <div className="d-flex w-100 ac-js mt-0">
            <img src={discountIcon} alt="" className="w-10" />
            <p className="f2 fs-13 fs-sm-13 fs-md-14 ms-1 ps-0 text-dark mt-0 mb-0 ">
              MemberShip Discounts
            </p>
          </div>
          <p className="f2 fs-13 fs-sm-14 fs-md-15 ms-0 ps-0 text-dark mt-0  mb-0">
            $0.00
          </p>
        </div>

        <div className="d-flex w-100 ac-jb mt-0">
          <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
            Total
          </p>
          <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
            $0.00
          </p>
        </div>
        <button className="w-100 bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2">
          Create Shipments
        </button>
      </div>
    </div>
  );
};

export default ProductsAndQuantity;
