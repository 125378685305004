// @ts-nocheck
import React, { useState } from "react";
import { commentIcon, closeIcon, happyEmoj, discountIcon, quickIcon } from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const UpdateQuantity = ({ showClickHandler, showUpdate }) => {
  const navi = useNavigate();
  const [number, setNumber] = useState('')


  return (
    <div className="tl-update-qty">
      <div
        className="back-update-qty"
        style={{ display: showUpdate ? "block" : "none" }}></div>
      <div
        className="update-qty update-qty-custom"
        style={{ right: showUpdate ? "0%" : "-100%" }}>
        <div className="d-flex ac-jb mt-2 mt-lg-4">
          <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
            Product Breakdown
          </h6>
          <button
            onClick={showClickHandler}
            className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2">
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <div className="btm-scroll pt-3 d-flex flex-column flex-md-row">
          <div className="prod-pack">
            <div className="d-flex ac-jb mt-2 mt-lg-4">
              <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
                Products inside the Pack
              </p>
              <div className="d-flex px-2">
                <button className="cust-btn f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 mb-0 sec mt-0">
                  Remove Product
                </button>
                <img className="commentIcon" src={commentIcon} alt="" />
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row ac-jb p-2 my-2 product-list-detail">
              <div className="box-shadow-product box-shadow-product-res">
                <h4 className=" mt-2 mb-4 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                  Pack 08/10/2023
                </h4>
                <div className="d-flex">
                  <div className="img-box-tee me-2">
                    <img
                      src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                      className="w-100"
                    />
                  </div>
                  <div className=" ms-2">
                    <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                      Next Level Crew Tee
                    </h3>
                    <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-1">
                      Color: <span className="f3">Solid White trible</span>
                    </p>
                    <div className="d-flex">
                      <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-1">
                        Price: $18.59
                      </p>
                      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-0 mb-1">
                        Qty: 100
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ms-3 right-box-cont w-xs-90">
            <div className="d-flex px-2 mt-4 align-items-center">
              <p className="cust-btn f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 mb-0 text-dark mt-0">
                Estimated Production time
              </p>
              <ErrorOutlineIcon className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-2 mb-0 pb-0 primary1 mt-0 " />
            </div>
            <div className="estimate-box p-3 p-lg-4 bg-lt-blue mt-3 rounded rounded-4">
              <div className="d-flex ac-jb">
                <h4 className="f3 primary fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 ">
                  Standard
                </h4>
                <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17 ">
                  $0.00
                </h4>
              </div>
              <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
                17 Business Days
              </p>
            </div>
            <div className="estimate-box p-3 p-lg-4 bg-lt-blue2 mt-3 rounded rounded-4">
              <div className="d-flex ac-jb">
                <h4 className="f3 text-dark fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                  Quick
                  <img src={quickIcon} className="dis-icon ms-1" />
                </h4>
                <h4 className="f4 text-dark fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17 ">
                  +$0.00
                </h4>
              </div>
              <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
                14 Business Days(10%)
              </p>
            </div>
            {/* Btn */}
            <div className="estimate-box mt-4 rounded rounded-4"
            >
              <h4 className="f3 text-dark fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-2 ">
                Quantity by sizes
              </h4>
              <div className="d-flex flex-wrap w-100">
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center ">
                    XS
                  </p>
                  <input class="num-cls-btn cust-btn
               f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark mt-2 text-center"
                    // value='100'
                    type="number"
                    placeholder="100"
                    maxlength={3}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    S
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    M
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    L
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    XL
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    2XL
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    3XL
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="mx-2 mt-2">
                  <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                    4XL
                  </p>
                  <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                    placeholder="0"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className=" bg-lt-blue mt-3 rounded">
              <div className="d-flex ac-jb mx-2 pt-1">
                <h4 className="f2 text-dark fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ac-js mt-1">
                  <img src={discountIcon} className="dis-icon mx-1" />
                  Membership Discounts
                </h4>
                <h4 className="f2 text-dark fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ac-js mt-1 ">
                  $0.00
                </h4>
              </div>
            </div>
            <div className="d-flex ac-jb mx-2 pt-1 mt-2">
              <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                Total Quantity
              </p>
              <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark ">
                25
              </h4>
            </div>
            <div className="Under-Line w-100 bg-lt-blue" />
            <div className="d-flex ac-jb mx-2 pt-1 mt-2">
              <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                Subtotal
              </p>
              <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                $1,0234
              </h4>
            </div>
            <div className="d-flex ac-jb mx-2 pt-1 mt-2">
              <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                Rush Production
              </p>
              <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                $0.00
              </h4>
            </div>
            <div className="Under-Line w-100 bg-lt-blue mt-2" />
            <div className="d-flex ac-jb mx-2 pt-1 mt-2">
              <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                Total Price
              </p>
              <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                $1,0234
              </h4>
            </div>
            <div className="d-flex ac-jc">
              <button class="get-start-btn mt-4 mb-3 border-0">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateQuantity;
