import React, { useState } from "react";
import ProductBreadCrumb from "../component/MyProductDetail/ProductBreadCrumb";
import ProductHeader from "../component/MyProductDetail/ProductHeader";
import ProductDetail from "../component/MyProductDetail/ProductDetail";
import ProductZoom from "../component/MyProductDetail/ProductZoom";
import { ProductColor } from "../component/MyProductDetail/ProductColor";
import ProductPopupComponent from "../component/MyProductDetail/ProductPopupComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import DeletePopup from "../component/Popup/DeletePopup";
import ReasonPopup from "../component/Popup/ReasonPopup";

const MyProductDetails = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const showPopupHander = () => {
    setShowModal(!showModal);
  };

  const showReasonPopupHander = () => {
    setShowReason(!showReason);
  };

  const deleteToggle = (type) => {
    setShowDelete(!showDelete);
    if (type == 1) {
      setShowReason(true);
    }
  };
  return (
    <div className="d-flex my-peoduct-det">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2 left-mock-req">
        <div className="d-flex ac-jb w-100">
          <div className="bread-crumb d-flex ac-jc bg-lt-blue">
            <Link to="/my-products" className="primary1">
              {"Product"}
            </Link>
            <ArrowForwardIosIcon className="primary" />
            <Link to="" className="primary">
              {"Product 0011"}
            </Link>
          </div>
          <div className="d-flex flex-column">
            <button
              className="mb-3 border-0 bg-approved text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-2 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18"
              onClick={() => navigate("/my-order")}
            >
              Create Order
            </button>
            <button className="mb-2 border-0 bg-pending text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-2 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18">
              Approve Design
            </button>
          </div>
        </div>
        <ProductHeader title="Product 0011" />
        {/* <ProductDetail /> */}
        <div className="d-flex flex-column flex-sm-row align-items-start p-2 my-2 product-list-detail mt-5">
          <div>
            {/* PRODUCT TILE START */}
            <div className="d-flex box-shadow-product my-3">
              <div className="img-box-tee me-2">
                <img
                  src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                  className="w-100"
                />
              </div>
              <div className=" ms-2">
                <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                  Next Level Crew Tee 1
                </h3>
                <p className="parag mb-0">₹18.59/per item</p>
                <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
                  Pending Review
                </p>
              </div>
            </div>
            <div className="d-flex box-shadow-product my-3">
              <div className="img-box-tee me-2">
                <img
                  src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                  className="w-100"
                />
              </div>
              <div className=" ms-2">
                <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                  Next Level Crew Tee 2
                </h3>
                <p className="parag mb-0">₹18.59/per item</p>
                <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
                  Pending Review
                </p>
              </div>
            </div>
            <div className="d-flex box-shadow-product my-3">
              <div className="img-box-tee me-2">
                <img
                  src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                  className="w-100"
                />
              </div>
              <div className=" ms-2">
                <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                  Next Level Crew Tee 3
                </h3>
                <p className="parag mb-0">₹18.59/per item</p>
                <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
                  Pending Review
                </p>
              </div>
            </div>
            {/* PRODUCT TILE END */}
          </div>
          <div className="d-flex flex-column flex-md-row product-zoom-color me-0 ms-auto d-flex">
            <ProductZoom />
            <ProductColor
              showPopupHander={showPopupHander}
              setShowDelete={setShowDelete}
            />
          </div>
        </div>
      </div>
      {showModal && <ProductPopupComponent showPopupHander={showPopupHander} />}
      {showDelete && <DeletePopup deleteToggle={deleteToggle} />}
      {showReason && (
        <ReasonPopup showReasonPopupHander={showReasonPopupHander} />
      )}
    </div>
  );
};

export default MyProductDetails;
