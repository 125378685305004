import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { discountIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const RequiredSummary = ({ OpenSummery, showClickHandler, showUpdate }) => {
  const navi = useNavigate();
  return (
    <div
      className="req-summary"
      style={{ right: OpenSummery ? "0px" : "-400px" }}>
      <h4 className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-20 ms-2 primary">
        Request Summary
      </h4>
      <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-3">
        #4678
      </h4>
      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-14 ms-2 primary1 mt-2">
        Date Requested : 08-17-2023
      </p>
      <h4 className="f3 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17 ms-2 text-dark mt-2">
        The Triblend Unisex Tee
      </h4>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          $18.15 X 100
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          $1815
        </p>
      </div>
      <button
        onClick={() => showUpdate(true)}
        className="cust-btn f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-primary mt-0">
        Update Quantities
        <ArrowForwardIosIcon className="cust-btn f2 fs-12 fs-lg-15 ms-2 text-primary mt-0" />
      </button>
      <div className="d-flex w-100 ac-jb mt-3">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Subtotal
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0">
          $1815.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Rush Production
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-30">
          $0.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Storage
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0 ">
          $100.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Shipping
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          $0.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Estimated Tax
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          $0.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0 memb-dics">
        <div className="d-flex w-100 ac-js mt-0">
          <img src={discountIcon} alt="" className="w-10" />
          <p className="f2 fs-13 fs-sm-13 fs-md-14 ms-1 ps-0 text-dark mt-0 mb-0 ">
            MemberShip Discounts
          </p>
        </div>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 ms-0 ps-0 text-dark mt-0  mb-0">
          $0.00
        </p>
      </div>
      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          Total
        </p>
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          $1,980.00
        </p>
      </div>
      <button
        onClick={() => navi("/select-product")}
        className="w-100 bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2">
        Continue
      </button>
    </div>
  );
};

export default RequiredSummary;
