// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { banner } from "../../assets/img";
import { useUserBannerMutation } from "../../redux/api/api";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import DOMPurify from "dompurify";

const HomeBanner = ({ list }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      data-bs-theme="dark"
      indicators={list?.length > 1 ? true : false}
    >
      {list?.map((item, ind) => {
        return (
          <Carousel.Item key={ind}>
            <div className="d-flex home-banner ac-jb">
              <div className="banner-left">
                <h4 className="f4">{item?.name.substring(0, 50)}</h4>
                <p
                  className=" f2 fs-13 fs-md-15 fs-lg-19 fs-xl-20 gray1 ms-1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      item?.description.substring(0, 100)
                    ),
                  }}
                >
                  {/* {item?.description.substring(0,100)} */}
                </p>

                <div className="cent-button d-flex ac">
                  <a className="get-start-btn border-0" href={item?.link}>
                    {" "}
                    {item?.button_name.substring(0, 30)}
                  </a>
                </div>
              </div>
              <div className="banner-rit">
                <img
                  src={item?.image_url ? item?.image_url : banner}
                  alt="banner"
                />
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default HomeBanner;
