// @ts-nocheck
import React from "react";
import { product1 } from "../../assets/img";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUp,
} from "@mui/icons-material";

const BulkProductCart = ({
  tick,
  pack,
  // addProductHandler,
  cartList,
  priceList,
  setShowPopup,
  deleteCart,
  getProductView,
  packs,
  totalPerPrice,
  totalPrice,
  setPricePack,
  popData,
  showoption,
  setShowOption,
  customQty,
  setNoOfPack,
  noOfPackErr,
  noOfPack,
  cartData,
}) => {
  const navigate = useNavigate();

  const addProductHandler = () => {
    navigate("/bulk-request", { pack: "build" });
  };

  const handleopen = (ind) => {
    if (showoption == ind) {
      setShowOption(null);
    } else {
      setShowOption(ind);
    }
  };

  return (
    <div className={`${tick ? "show-cart" : "hide-cart"} prod-cart`}>
      <div className="position-relative h-100">
        <h4>Your Product</h4>
        <div className="bulk-list-cart">
          {cartList?.map((item, ind) => {
            return (
              <>
                <div className="prod-cart-list w-100 d-flex ac-jb">
                  <div
                    className=" d-flex ac-js"
                    onClick={() => {
                      setShowPopup();
                      // getProductView(item?.product?.id,);
                      getProductView(item?.product?.id, item?.id, item);
                    }}
                  >
                    <div className="img-box">
                      <img
                        src={
                          item?.product?.defaultImage
                            ? item?.product?.defaultImage
                            : product1
                        }
                        alt=""
                      />
                    </div>
                    <div className="mx-2">
                      <h5>{item?.product?.name}</h5>
                      <p>Price starting at ₹ {item?.total_price}</p>
                    </div>
                  </div>
                  <button
                    className="cust-btn"
                    onClick={() => {
                      deleteCart(item?.id, item?.product?.id);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
                {showoption == null && (
                  <div className="d-flex ac-jb mb-3">
                    <h6 className="mb-0">
                      Quantity
                      <span className="primary1 fs-14">
                        {" "}
                        (Min {cartData?.[item?.product?.id]?.count})
                      </span>
                    </h6>

                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handleopen(ind)}
                    >
                      <KeyboardArrowDownOutlined />
                    </button>
                  </div>
                )}
                {showoption == ind && (
                  <div>
                    <div className="d-flex ac-jb">
                      <p className="f3 fs-11 fs-sm-12 fs-md-13 fs-lg-14 text-dark mt-0 mb-0">
                        Select Quantity
                      </p>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => setShowOption(null)}
                      >
                        <KeyboardArrowUp />
                      </button>
                    </div>
                    <p className="f2 fs-11 fs-sm-13 fs-md-14 fs-lg-10 primary1 mt-3 mb-3 text-center">
                      The more you order, the more you save
                    </p>
                    {/* {priceList?.map((item, index) => { */}
                    {/* return ( */}
                    <div className="" onClick={() => setShowOption(null)}>
                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            25,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${25}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          25
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_25}
                          {/* {+item?.product?.for_25 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>

                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            50,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${50}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          50
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_50}
                          {/* {+item?.product?.for_50 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>

                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            100,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${100}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          100
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_100}
                          {/* {+item?.product?.for_100 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>
                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            150,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${150}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          150
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_150}
                          {/* {+item?.product?.for_150 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>
                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            250,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${250}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          250
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_250}
                          {/* {+item?.product?.for_250 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>
                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            500,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${500}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          500
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_500}
                          {/* {+item?.product?.for_500 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>
                      <div
                        className="d-flex ac-jb py-2 px-1 select-list"
                        role="button"
                        onClick={() =>
                          customQty(
                            1000,
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            item?.product?.[`for_${1000}`],
                            "test"
                          )
                        }
                      >
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          1000
                        </p>
                        <p className="f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 text-center my-0">
                          ₹ {+item?.product?.for_1000}
                          {/* {+item?.product?.for_1000 *
                            +cartData?.[item?.product?.id]?.count} */}
                        </p>
                      </div>
                    </div>
                    {/* );
              })} */}
                    <div className="d-flex select_input ac-jb mt-3 mb-3 h-100 w-100 py-2 px-1">
                      <input
                        type="number"
                        className="bg-transparent border-0 h-100 w-100 f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 ps-3"
                        placeholder="Custom quantity"
                        onChange={(e) => setNoOfPack(e.target.value)}
                        value={noOfPack}
                      />
                      <button
                        className="my-0 border-0 bg-transparent f3 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary text-start"
                        onClick={() =>
                          customQty(
                            "",
                            item?.product?.id,
                            item?.product_sku?.id,
                            item?.no_of_colors,
                            "",
                            "test",
                            item
                          )
                        }
                      >
                        Enter
                      </button>
                    </div>
                    {noOfPack?.length == 0 && noOfPackErr && (
                      <div className="d-flex gap-1">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Custom quantity
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
          {/* <div className="prod-cart-list w-100 d-flex ac-jb">
            <div className=" d-flex ac-js">
              <div className="img-box">
                <img src={product1} alt="" />
              </div>
              <div className="">
                <h5>Inset cards by Moodyqwdui uiy uiy</h5>
                <p>Price starting at $1.68</p>
              </div>
            </div>
            <button className="cust-btn">
              <DeleteIcon />
            </button>
          </div> */}
          {/* <div className="d-flex ac-jb mb-3">
            <h6 className="mb-0">
              Quantity<span className="primary1 fs-14"> (Min 25)</span>
            </h6>
            <select className="cust-btn f4" name="" id="">
              <option value="100">100</option>
              <option value="25">500</option>
            </select>
          </div> */}
        </div>
        <div
          className={`${pack == "build" ? "build-btm-cart" : "bulk-btm-cart"}`}
        >
          <div className="d-flex ac-jb mb-2">
            <h5 className="">
              Total Estimate <ErrorOutlineIcon className="fs-13 primary1" />
            </h5>
            <h5 className="">₹ {totalPrice}</h5>
          </div>
          <button
            onClick={addProductHandler}
            className="cust-btn bg-primar get-start-btn mx-auto mb-0 mb-1 mt-0 w-90"
          >
            Add Project Detail
          </button>
          <p className="primary1 fs-16 f2 text-center">
            No credit card info required
          </p>
        </div>
      </div>
    </div>
  );
};

export default BulkProductCart;
