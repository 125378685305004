// @ts-nocheck
import React, { useEffect, useState } from "react";
import LeftProduct from "../component/Product/Ecommerce/LeftProduct";
import ProductPopupComponent from "../component/Product/Ecommerce/ProductPopupComponent";
import RightProducts from "../component/Product/Ecommerce/RightProducts";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import EcommerceProductCart from "../component/Product/EcommerceProductCart";
import EcommerceMock from "../component/Product/EcommerceMock/TellProject";
import {
  useAddToCartMutation,
  useCartListMutation,
  useDeleteCartMutation,
  useProductListMutation,
  useProductViewMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const EcommerceProductScreen = () => {
  const Token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [packsPrice, setPacksPrice] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  // const [cartBtn, setCartBtn] = useState(false);
  const [viewId, setViewId] = useState("");
  const [productList, setProductList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [viewList, setViewList] = useState([]);
  const [tick, setTick] = useState([]);
  const [style, setStyle] = useState({});
  const [size, setSize] = useState({});
  const [material, setMaterial] = useState({});
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [cartData, setCartData] = useState({});
  const [imagesid, setImagesId] = useState({});
  const [bigImg, setBigImg] = useState(0);
  const [count, setCount] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ListPrice, setListPrice] = useState({});
  const [skuCodeList, setCodeList] = useState([]);
  const [no_pack, setNo_of_pack] = useState(25);
  const [cartBtn, setCartBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [cortList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState();
  const [pack, setPack] = useState(25);
  const [totalPrice, setTotalPrice] = useState("");
  const [showoption, setShowOption] = useState(true);
  const [userCartID, setUserCartID] = useState("");
  const [varientsName, setVarientName] = useState({});
  const [qtyBtn, setQtyBtn] = useState(false);

  const showPopupHander = () => {
    setShowPopup(!showPopup);
    // console.log("099");
  };
  const addProductHandler = (e) => {
    // console.log("products:", e);
    setTick(!tick);
  };
  //RTK
  const [ProductListApi] = useProductListMutation();
  const [productViewApi] = useProductViewMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [cartListApi] = useCartListMutation();
  const [deleteApi] = useDeleteCartMutation();

  // ADD TO CART
  const varients = (
    id,
    qty,
    packsPrice,
    colors_name,
    styles_name,
    materials_name,
    sizes_name,
    skuCodes,
    type
  ) => {
    let temp = "";

    if (selectedColorId) {
      temp = `/${selectedColorId}`;
    }

    if (size[id]) {
      temp = temp + `/${size[id]}`;
    } else {
      temp = temp + `/`;
    }

    if (material[id]) {
      temp = temp + `/${material[id]}`;
    } else {
      temp = temp + `/`;
    }

    if (style[id]) {
      temp = temp + `/${style[id]}`;
    } else {
      temp = temp + `/`;
    }

    // console.log("temp", temp);
    let codeFinder;

    codeFinder = skuCodeList?.find((i) => i?.variant_name == temp);

    if (codeFinder?.id) {
      dispatch(saveLoader(true));
      setCartBtn(true);
      getAddToCart(codeFinder?.id, id, qty, packsPrice);
    } else {
      dispatch(saveLoader(false));
      setCartBtn(false);
    }
  };
  const varientsNew = (
    id,
    qty,
    packsPrice,
    colorID,
    colorName,
    noOfColors,
    size,
    material,
    style
  ) => {
    let temp_str = "";

    if (colorName) {
      temp_str = `/${colorName}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (size) {
      temp_str = temp_str + `/${size}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (material) {
      temp_str = temp_str + `/${material}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (style) {
      temp_str = temp_str + `/${style}`;
    } else {
      temp_str = temp_str + `/`;
    }

    // let codeFinder = skuCodeList?.find((i) => i?.color_id == colorID);
    let codeFinder = skuCodeList?.find((i) => i?.variant_name == temp_str);
    if (codeFinder?.id) {
      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      // console.log("codeFinder", codeFinder);
      // if (!Token) {
      // updateLocalCart(id, qty, packs_val, codeFinder, noOfColors, type);
      // } else {
      let pack_value = cartData?.[id]?.pack;

      let count_value = cartData?.[id]?.count
        ? +cartData?.[id]?.count
        : +cartData?.[id]?.count_value;

      let no_of_colors = noOfColors
        ? noOfColors
        : cartData?.[id]?.no_of_colors
        ? +cartData?.[id]?.no_of_colors
        : 1;

      let temp = [];

      temp.push({
        product_id: id,
        product_sku_id: codeFinder?.id,
        no_of_colors: no_of_colors,
        qty: qty ? qty : count_value,
        price: +packsPrice ? +packsPrice : +packs_val,
      });

      // console.log("temp", temp);

      // console.log("pack_value", pack_value);

      const formData = new FormData();
      formData.append("type", 4);
      formData.append("no_of_packs", 1);
      // formData.append("preset_pack_id", " ");

      if (temp?.length > 0) {
        temp?.map((item, ind) => {
          formData.append(`cart_details[${ind}][qty]`, item?.qty);
          formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
          if (item?.product_sku_id) {
            formData.append(
              `cart_details[${ind}][product_sku_id]`,
              item?.product_sku_id
            );
          }

          formData.append(`cart_details[${ind}][price]`, item?.price);
          formData.append(
            `cart_details[${ind}][no_of_colors]`,
            item?.no_of_colors
          );
        });
        // }

        // console.log("temp", temp);

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            // if (!qty) {
            //   showPopupHander();
            // }
            // const temp = [];
            // temp.push(viewId);
            // setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };

  // ADD CART
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: no_of_colors,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    const formData = new FormData();
    formData.append("type", 4);
    formData.append("no_of_packs", 1);

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        let qty_sum = +item?.qty * +25;

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          qty_sum = 25;
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          qty_sum = 50;
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          qty_sum = 100;
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          qty_sum = 150;
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          qty_sum = 250;
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          qty_sum = 500;
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          qty_sum = 1000;
        } else if (+qty_sum > 1000) {
          qty_sum = 1000;
        }

        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product_sku_id
        );

        if (item?.product_id == ListPrice?.id) {
          formData.append(
            `cart_details[${ind}][price]`,
            ListPrice?.[`for_${qty_sum}`]
          );
        } else {
          formData.append(`cart_details[${ind}][price]`, item?.price);
        }
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        if (!qty) {
          showPopupHander();
        }
        const temp = [];
        temp.push(viewId);
        setTick(temp);
        getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  // DELETE CART
  const deleteCart = (id, pro_id) => {
    const formData = new FormData();
    formData.append("user_cart_detail_id", id);
    dispatch(saveLoader(true));
    setDeleteBtn(true);
    deleteApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setDeleteBtn(false);
        let obj = { ...cartData };
        delete obj[pro_id];
        setCartData(obj);
        getCartApi();
        let temp = [...tick];
        let inx = temp.indexOf(pro_id);
        temp.splice(inx, 1);
        setTick(temp);
        setShowPopup(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setDeleteBtn(false);
        console.log("err", err);
      });
  };

  // CART LIST
  const getCartApi = () => {
    const formData = new FormData();
    formData.append("type", 4);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        const data = res?.cart_detail?.cartDetail;
        const temp = [];
        let tempArr = [];
        data?.map((item) => {
          temp?.push(item?.product?.id);
          setTick(temp);
        });

        if (res?.cart_detail?.id) {
          let qtys = 25;
          let total_price_cart;
          setNo_of_pack(25);
          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let qty_val = item?.product?.[`for_${qtys}`];
            let sum = +item?.qty * qtys;
            let obj = {
              for_price: +qty_val,
              pack: 1,
              count: +item?.qty,
              cart_id: item?.id,
              no_of_colors: item?.no_of_colors,
            };

            if (qty_val) {
              obj.for_price = +qty_val;
            } else {
              obj.for_price = +qtys;
            }

            if (+sum >= 1 && +sum <= 25) {
              obj.packs_price = +item?.product?.for_25;
              total_price_cart = +item?.product?.for_25;
            } else if (+sum > 25 && +sum <= 50) {
              obj.packs_price = +item?.product?.for_50;
              total_price_cart = +item?.product?.for_50;
            } else if (+sum > 50 && +sum <= 100) {
              obj.packs_price = +item?.product?.for_100;
              total_price_cart = +item?.product?.for_100;
            } else if (+sum > 100 && +sum <= 150) {
              obj.packs_price = +item?.product?.for_150;
              total_price_cart = +item?.product?.for_150;
            } else if (+sum > 150 && +sum <= 250) {
              obj.packs_price = +item?.product?.for_250;
              total_price_cart = +item?.product?.for_250;
            } else if (+sum > 250 && +sum <= 500) {
              obj.packs_price = +item?.product?.for_500;
              total_price_cart = +item?.product?.for_500;
            } else if (+sum > 500 && +sum <= 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            } else if (+sum > 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            }

            tempArr.push({
              ...item,
              // total_price: total_price_cart,
            });

            // console.log("obj", obj);

            setCartData((val) => ({
              ...val,
              [item?.product?.id]: obj,
            }));
          });

          let varient_size = {};
          let varient_material = {};
          let varient_style = {};

          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let variant_name = item?.product_sku?.variant_name;
            let remove = variant_name?.split("/");
            varient_size[item?.product?.id] = remove[2];
            varient_material[item?.product?.id] = remove[3];
            varient_style[item?.product?.id] = remove[4];
          });

          setSize(varient_size);
          setMaterial(varient_material);
          setStyle(varient_style);
          setPack(res?.cart_detail?.no_of_packs);
          setTotalPrice(res?.cart_detail?.total_amount);
          // setTotalPerPrice(res?.cart_detail?.price_per_pack);
          setCartList(tempArr);
          setPriceList(res?.pack_prices);
        } else {
          setCartList([]);
          setPriceList({});
        }
        dispatch(saveLoader(false));
        setQtyBtn(false);
      })
      .catch((err) => {
        setQtyBtn(false);
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  // TICK PRODUCT
  const onClickProduct = (product) => {
    const formData = new FormData();
    setViewId(product);
    formData?.append("product_ids", product);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: product })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setSelectedColorId(res?.product?.colors?.[0]?.name);
        setSelectColorId(res?.product?.colors?.[0]?.id);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let final = {
          ...obj,
          [res?.product?.id]: {
            ...object,
            for_price: +res?.product?.for_25,
            pack: 1,
            count_value: 1,
          },
        };

        const temp = [];
        temp?.push(res?.product);

        const list = [];
        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            list.push({ img: color?.image_url });
          });
          item?.colors?.map((hex) => {
            list?.push({ ...hex, hex_code: hex?.hex_code });
          });

          image?.push({
            ...item,
            images: list,
          });
        });
        // console.log(image);
        // setStyle(ima)
        setViewList(image);

        let tempPro = [];

        tempPro.push({
          product_id: res?.product?.id,
          product_sku_id: res?.product?.product_varient_skus?.[0]?.id,
          no_of_colors: 1,
          qty: 1,
          price: res?.product?.[`for_${25}`],
        });

        const formData = new FormData();
        formData.append("type", 4);
        formData.append("no_of_packs", 1);

        if (tempPro?.length > 0) {
          tempPro?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );
            formData.append(
              `cart_details[${ind}][product_sku_id]`,
              item?.product_sku_id
            );
            formData.append(`cart_details[${ind}][price]`, item?.price);
          });
        }

        addToCartApi(formData)
          .unwrap()
          .then((resp) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            const temp = [...tick];
            // console.log(temp)
            temp.push(product);
            setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
        setCartBtn(false);
      });
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        let obj = { ...cartData };
        let colorFinder = res?.product?.colors?.find(
          (i) => i?.id == item?.product_sku?.color_id
        );

        let findIndex = res?.product?.colors?.findIndex(
          (i) => i?.id == item?.product_sku?.color_id
        );

        setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product_id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }
        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        if (!obj[res?.product?.id]?.cart_id) {
          setStyle((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.styles?.[0]?.value,
          }));
          setMaterial((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.materials?.[0]?.value,
          }));
          setSize((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.sizes?.[0]?.value,
          }));
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };
        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );
            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.name);
          setSelectColorId(colorFinder?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(res?.product?.colors?.[0]?.name);
          setSelectColorId(res?.product?.colors?.[0]?.id);
          setBigImg(0);
        }

        // console.log(image, "images");
        setViewList([
          {
            ...res?.product,
            images: image,
          },
        ]);
        showPopupHander();
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  //PRODUCT VIEW
  const product = () => {
    const formData = new FormData();
    ProductListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res?.navigations);
        setCategoryList(res?.navigations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculations = (qty, id, packs, type) => {
    setQtyBtn(true);
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;
    obj.sku_id = +cartData?.[id]?.sku_id;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);
    setNo_of_pack(+packs);

    if (+cartData?.[id]?.cart_id) {
      obj.cart_id = +cartData?.[id]?.cart_id;
      varients(
        id,
        qty_val,
        obj?.packs_price ? obj?.packs_price : obj.for_price
      );
    } else {
      setQtyBtn(false);
    }
  };

  useEffect(() => {
    product();
  }, []);

  useEffect(() => {
    if (Token) {
      getCartApi();
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      {/* <LeftProduct /> */}
      <RightProducts
        tick={tick}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
        categoryList={categoryList}
        setViewId={setViewId}
        setTick={setTick}
        onClickProduct={onClickProduct}
        // getAddToCart={getAddToCart}
        getProductView={getProductView}
        cartData={cartData}
        deleteCart={deleteCart}
        userCartID={userCartID}
      />
      {cortList?.length > 0 && (
        <EcommerceProductCart
          pack="bulk"
          tick={tick}
          categoryList={categoryList}
          addProductHandler={addProductHandler}
          cortList={cortList}
          priceList={priceList}
          setShowPopup={showPopupHander}
          deleteCart={deleteCart}
          getProductView={getProductView}
          packs={pack}
          totalPrice={totalPrice}
          showoption={showoption}
          deleteBtn={deleteBtn}
        />
      )}
      {showPopup && (
        <ProductPopupComponent
          varientsNew={varientsNew}
          calculations={calculations}
          showPopupHander={showPopupHander}
          viewList={viewList}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          setCartData={setCartData}
          cartData={cartData}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          cartData={cartData}
          imagesid={imagesid}
          bigImg={bigImg}
          setBigImg={setBigImg}
          count={count}
          setCount={setCount}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          varients={varients}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          userCartID={userCartID}
          qtyBtn={qtyBtn}
        />
      )}
      {tick?.length > 0 && (
        <button
          onClick={addProductHandler}
          className="cust-btn d-flex ac-jc d-xl-none mobile-cart"
        >
          <ShoppingBagIcon />
        </button>
      )}
    </div>
  );
};
export default EcommerceProductScreen;
