// @ts-nocheck
import React, { useState } from "react";
import {
  commentIcon,
  closeIcon,
  happyEmoj,
  discountIcon,
  searchIcon,
} from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

const AddExistingRecipient = ({ showClickHandler, showUpdate }) => {
  const [selectRec, setSelectRec] = useState(false);
  const [selectSingle, setSelectSingle] = useState(false);

  const navi = useNavigate();
  return (
    <div className="tl-update-qty">
      <div
        className="back-update-qty"
        style={{ display: showUpdate ? "block" : "none" }}
      ></div>
      <div
        className="update-qty"
        style={{ right: showUpdate ? "0%" : "-100%" }}
      >
        <div className="d-flex ac-jb mt-2 mt-lg-4">
          <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
            Select Recipients
          </h6>
          <button
            onClick={showClickHandler}
            className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
          >
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <div className="btm-scroll pt-3 d-flex flex-column flex-md-row">
          <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
            <img src={searchIcon} alt="" className="searchImgIcon" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f2 fs-6 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search"
              name=""
              id=""
            />
          </div>
          <div className="filterView">
            <p className="fs-xs-11 filterDesign">Filters</p>
            <select className="cust-btn seletDesign"></select>
          </div>
          <div className="filterView">
            <p className="fs-xs-11 filterDesign">Sort by</p>
            <select className="cust-btn seletDesign"></select>
          </div>
          <button className="cust-btn filterView1">
            <h4 className="f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
              +Add Recipients
            </h4>
          </button>
        </div>
        <div className="btm-scroll w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-5">
          <div
            className="d-flex w-10 "
            onClick={() => {
              setSelectRec(!selectRec);
            }}
          >
            <DoneIcon
              className={`${selectRec ? "tickBoxDesign11" : "tickBoxDesign41"
                } rounded-1`}
            />
          </div>
          <p className="fs-xs-11 filterDesign1 ">Name</p>
          <p className="fs-xs-11 filterDesign2">Address</p>
          <p className="fs-xs-11 filterDesign3">Size</p>
          <p className="fs-xs-11 filterDesign3">Groups</p>
        </div>
        <div className="btm-scroll mt-5 w-100 pt-3 d-flex flex-column flex-md-row">
          <div
            className="d-flex w-10"
            onClick={() => {
              setSelectSingle(!selectSingle);
            }}
          >
            <DoneIcon
              className={`${selectSingle || selectRec
                  ? "tickBoxDesign11"
                  : "tickBoxDesign41"
                } rounded-1 w-10`}
            />
          </div>
          <h6 className="fs-xs-11 f2 fs-sm-13 fs-md-14 fs-xl-15 filterDesign11 ">
            Jake Adams
          </h6>
          <h6 className="fs-xs-11 f2 fs-sm-13 fs-md-14 fs-xl-15 filterDesign21">
            Shop No.230 BC Third Floor, Chen...
          </h6>
          <h6 className="fs-xs-11 f2 fs-sm-13 fs-md-14 fs-xl-15 filterDesign31">
            L
          </h6>
          <h6 className="fs-xs-11 f2 fs-sm-13 fs-md-14 fs-xl-15 filterDesign31">
            Group
          </h6>
        </div>
      </div>
      {showUpdate && (
        <div className="position-fixed continueBtn1">
          <button className=" w-100 pt-2 pb-2 ps-3 pe-3 bg-primar border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark mt-2">
            Add Selected Recipients(0)
          </button>
        </div>
      )}
    </div>
  );
};

export default AddExistingRecipient;
