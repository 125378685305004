import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";

const ProductHeader = ({ title }) => {
  return (
    <div className="my-prod-det d-flex  flex-column flex-md-row  as-jb">
      <h4 className="sect-title mb-1 mb-md-0 w-100 w-lg-50">{title}</h4>
      <div className="w-100 w-lg-50 as-jb d-flex mt-3">
        <p className="f4 fs-13 fs-sm-16 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark mb-0">
          Price $18.10
        </p>
        <div className="">
          <p className="f2 fs-12 fs-sm-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 mb-1 mb-md-2">
            Mockup presentation file
          </p>
          <button className="f3 cust-btn fs-13 fs-sm-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-primary mb-0">
            Mockup PDF <GetAppIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
