// @ts-nocheck
import React from "react";
import { closeIcon, logist, product1 } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import YourStorageComp from "./YourStorageComp";
import { useState } from "react";

const ProductList = ({ showPopupHander, tick }) => {
  console.log(tick);
  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);

  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(!showUpdate);
  };
  return (
    <div className="login-product d-flex flex-wrap">
      {[0, 1, 2]?.map((item, ind) => {
        return (
          <div
            className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-box-tl"
            key={ind}
          >
            <div className="prod-list-box">
              <div className="">
                <p
                  className={`lt-ready fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3`}
                  style={
                    ind == 0
                      ? { background: "#f0bc10", color: "white" }
                      : { background: "#198754", color: "white" }
                  }
                >
                  {ind == 0
                    ? "Design In Process"
                    : ind == 1
                    ? "Ready to Buy"
                    : ind == 2
                    ? "Ready to Buy"
                    : ""}
                </p>
                <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                  Order ID 00{ind + 1}
                </h3>
                <div className="d-flex align-items-center">
                  <img className="logist" src={logist} alt="" />
                  <p className="parag mb-0 ms-2 fs-xl-16"> 25 Qty</p>
                </div>
              </div>
              <button className="cust-btn prod-list-box-img">
                <img
                  src={product1}
                  alt=""
                  style={{ height: "200px", objectFit: "contain" }}
                />
              </button>
              <div className="d-flex justify-content-between ">
                <p className="parag mb-0 ms-2 fs-xl-16"> Order Requested</p>
                <p className="parag text-dark mb-0 ms-2 fs-xl-16">
                  {" "}
                  25-07-2024
                </p>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="parag mb-0 ms-2 fs-xl-16">Total</p>
                <p className="parag text-dark mb-0 ms-2 fs-xl-16">₹3,00,000</p>
              </div>
              <div className="d-flex justify-content-center ">
                <button
                  // onClick={showPopupHander}
                  onClick={() => {
                    if (ind !== 0) {
                      navigate("/my-shipment");
                    }
                  }}
                  className={`get-start-btn border-0 ${
                    ind == 0 ? "opacity-50" : ""
                  }`}
                >
                  View Request
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
