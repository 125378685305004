// @ts-nocheck
import React from "react";
import { logist, product1, productd } from "../../assets/img";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const ProductList = ({
  showPopupHander,
  addProductHandler,
  tick,
  showCartHandler,
}) => {
  console.log(tick);
  const navigate = useNavigate();
  return (
    <div className="login-product d-flex flex-wrap">
      <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-login">
        <div className="prod-list-box">
          <div className="d-flex ac-jb">
            <p
              className={`bg-lt-design design fs-11 fs-md-12 f2 rounded rounded-2 mb-0 py-1 px-2`}
            >
              Pending Mockup
            </p>
            <div className="d-flex align-items-center">
              <img className="logist" src={logist} alt="" />
              <p className="parag sec mb-0 ms-2 f4"> packs</p>
            </div>
          </div>
          <button
            onClick={showPopupHander}
            className="cust-btn prod-list-box-img"
          >
            <img src={product1} alt="" />
          </button>
          <div className="btm-prod">
            <h3>Product 1020</h3>
            <p className="primary1 f2 mb-0 pb-0">₹23.0</p>
            <div className="d-flex justify-content-between">
              <button
                onClick={() => navigate("/my-product-detail")}
                className="cust-btn f4 fs-15 fs-md-15 primary"
              >
                View Mockup{" "}
                <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
              </button>
              {/* <button onClick={() => showCartHandler(1)} className="plus">
                {tick ? <CheckIcon /> : <AddIcon />}
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-login">
        <div className="prod-list-box">
          <div className="d-flex ac-jb">
            <p
              className={`bg-lt-ready ready fs-11 fs-md-12 f2 rounded rounded-2 mb-0 py-1 px-2`}
            >
              Product Ready
            </p>
            <div className="d-flex align-items-center">
              <img className="logist" src={logist} alt="" />
              <p className="parag sec mb-0 ms-2 f4"> packs</p>
            </div>
          </div>
          <button
            onClick={showPopupHander}
            className="cust-btn prod-list-box-img"
          >
            <img src={productd} alt="" />
          </button>
          <div className="btm-prod">
            <h3>Product 0092</h3>
            <p className="primary1 f2 mb-0 pb-0">₹23.0</p>
            <div className="d-flex justify-content-between">
              <button
                onClick={() => navigate("/my-product-detail")}
                className="cust-btn f4 fs-15 fs-md-15 primary"
              >
                View Mockup{" "}
                <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
              </button>
              {/* <button onClick={() => showCartHandler(1)} className="plus">
                {tick ? <CheckIcon /> : <AddIcon />}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
