import React, { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import "./FullProducts.css";
import {
  accesories,
  backpack,
  caps,
  discount,
  discountIcon,
  events,
  fitness,
  gadgets,
  home,
  hoodies,
  jackets,
  kit,
  listIcon,
  notebooks,
  packaging,
  poloShirt,
  shift,
  snakables,
  SpecialCateg,
  totes,
  travel,
  tshirts,
  tumbler,
  waterBottle,
} from "../../../assets/img";
import {
  useCategoryListMutation,
  useColorListMutation,
  useLazyTagsfilterQuery,
} from "../../../redux/api/api";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { Searcher, product1, productd, ticker } from "../../../assets/img";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RangeSet from "../../Range/RangeSet";
import { ArrowDropDown } from "@mui/icons-material";
// import Scrollspy from "react-scrollspy";
import "bootstrap/dist/css/bootstrap.min.css";
import DoneIcon from "@mui/icons-material/Done";
import CachedIcon from "@mui/icons-material/Cached";

const FullProduct = ({
  tick,
  categoryList,
  showPopupHander,
  addProductHandler,
  setViewId,
  onClickProduct,
  getAddToCart,
  getProductView,
  cartData,
  deleteCart,
  userCartID,
  product,
  values,
  setValues,
  setNormalId,
  SetPriceSort,
  priceSort,
  search,
  setSearch,
  colorId,
  setColorId,
}) => {
  const [categShow, setCategShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [normal, setNormal] = useState([]);
  const [event, setEvent] = useState([]);
  const [icon, setIcon] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [color, setColor] = useState(false);
  // const [search, setSearch] = useState("");
  const [drag, setDrag] = useState(false);
  const [normalid, setnormalid] = useState();
  const [eventid, setEventid] = useState();
  const [iconid, setIconid] = useState();

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const HandledDrag = () => {
    setDrag(!drag);
  };
  const ColorDropdown = () => {
    setColor(!color);
  };
  const HandledSearch = (e) => {
    const SearchTerm = e.target.value;
    setSearch(SearchTerm);
  };
  const categShowHandler = () => {
    setCategShow(!categShow);
  };

  const [activeSection, setActiveSection] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [tagsListApi] = useLazyTagsfilterQuery();
  const [colorListApi] = useColorListMutation();

  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setNormal(res?.normalTag);
        setEvent(res?.eventTag);
        setIcon(res?.iconTag);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColorsList = () => {
    colorListApi()
      .unwrap()
      .then((res) => {
        setColorList(res?.colors);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTagsList();
    getColorsList();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY + 100;

      sections.forEach((section) => {
        if (
          section.offsetTop <= scrollPosition &&
          section.offsetTop + section.offsetHeight > scrollPosition
        ) {
          setActiveSection(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    // setSidebarOpen(false);
  };

  // console.log(activeSection, "activeSection");
  // console.log(activeSection, "activeSection");
  const handleColorClick = (id) => {
    product(id, "");
    setColor(!color);
  };
  const tags = () => {
    let temp = [];
    if (normalid) {
      temp.push(normalid);
    }
    if (iconid) {
      temp.push(iconid);
    }
    if (eventid) {
      temp.push(eventid);
    }
    //  }else if(eventid){
    //  }else{
    //  }
    setNormalId(temp);
  };
  useEffect(() => {
    tags();
  }, [normalid, eventid, iconid]);

  const lowpriceHandel = () => {
    SetPriceSort("low");
    setShowFilter(!showFilter);
  };
  const highpriceHandel = () => {
    SetPriceSort("high");
    setShowFilter(!showFilter);
  };
  const refreshHandel = () => {
    SetPriceSort("low");
    setSearch("");
    setColorId("");
    setnormalid("");
    setEventid("");
    setIconid("");
    setValues([5, 1000]);
    product("", "");
  };

  return (
    <>
      <div
        className={`${
          categShow ? "left-product-show" : "left-product-hid"
        } left-product `}
      >
        <button onClick={categShowHandler} className="cat-title cust-btn">
          <ListIcon />
          <p>Categories</p>
        </button>

        <div className="d-flex align-items-center justify-content-lg-center ">
          <div>
            {categoryList?.map((section, ind) => {
              // console.log(
              //   activeSection == section?.id,
              //   activeSection,
              //   section?.id
              // );
              return (
                <li
                  className="d-flex align-items-center list-box"
                  key={section?.id}
                >
                  <div className=" border-0 img-back  d-flex ac-jc my-1">
                    <img src={section?.icon_url} alt="" />
                  </div>
                  <a
                    className={`mb-0 ms-3 f3  ${
                      +activeSection === section?.id ? "activelist" : ""
                    }`}
                    style={{ width: "135px" }}
                    href={`#${
                      section?.products?.length > 0
                        ? section?.id
                        : "/products/build-pack"
                    }`}
                    onClick={(e) => {
                      if (section?.products?.length > 0) {
                        e.preventDefault();
                        scrollToSection(section?.id);
                      }
                    }}
                  >
                    {section?.name}
                  </a>
                </li>
              );
            })}
          </div>
        </div>
      </div>
      <div className="withoutcart rit-prod bulk-prod mt-3">
        <h4 className="sect-title build mb-2 mb-lg-4">Design Your Kit</h4>
        {/* <div> */}
        <div className="d-flex gap-4 mt-4 justify-content-between w-90 flex-wrap ms-1">
          <div className="position-relative">
            <button
              className="btn bg-btn-blue white shad px-5"
              onClick={ColorDropdown}
            >
              {" "}
              <p className="f3 m-0 fs-lg-18"> Color</p>
            </button>
            {color && (
              <div className="popup d-flex flex-column as-jc">
                <h6>Select Color</h6>
                <div className="d-flex  gap-1">
                  {colorList?.map((item, ind) => (
                    <button
                      style={{ background: `${item?.hex_code}` }}
                      className="img"
                      onClick={() => {
                        handleColorClick(item?.id);
                      }}
                    >
                      {colorId == item?.id ? (
                        <DoneIcon className="text-white fs-25" />
                      ) : (
                        ""
                      )}
                      {/* <img src={ticker} alt="" className="img" /> */}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
          <div className="position-relative">
            <select
              className="btn btn-light shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
              value={normalid}
              onChange={(e) => {
                setnormalid(e.target.value);
              }}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p>Normal Tags</p>
              </option>
              {normal?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          <div className="position-relative">
            <select
              className="btn btn-light shadow-box1 text-start px-3 f3 m-0 fs-lg-18"
              value={eventid}
              onChange={(e) => {
                setEventid(e.target.value);
              }}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p> Event Tags</p>
              </option>
              {event?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          <div className="position-relative">
            <select
              className="btn btn-light shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
              value={iconid}
              onChange={(e) => setIconid(e.target.value)}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p> Icon Tags</p>
              </option>
              {icon?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          <div className="position-relative">
            <button
              className="btn btn-light shadow-box2 px-3"
              onClick={HandledDrag}
            >
              <p className="f3 m-0 fs-lg-18">Budget</p>{" "}
            </button>
            <div className="mt-2">
              {drag && <RangeSet setValues={setValues} values={values} />}
            </div>
          </div>
          <div className="d-flex gap-4 mt-4 mb-3 justify-content-between  w-100 flex-wrap  m-auto">
            <div className="d-flex gap-2">
              <div className="border-5 position-relative ps-2">
                <img src={Searcher} alt="" className="imgs" />
                <input
                  type="text"
                  className="inputcontainer  border-0"
                  value={search}
                  onChange={(event) => {
                    product("", event.target.value);
                    // setSearch(event.target.value);
                  }}
                  placeholder="Search by tags/Products"
                />
              </div>
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-2 rounded"
                onClick={() => refreshHandel()}
              >
                <CachedIcon />
              </button>
            </div>
            <div className="d-flex ac-je position-relative mt-2">
              <p className="filters mb-0 f3">Sort By :</p>
              <div className="d-flex ac-jc">
                <p className="filtertxt mb-0">Featured</p>
                <ArrowDropDown onClick={toggleFilter} />
              </div>

              {showFilter && (
                <div className="filter-options position-absolute ">
                  <p>Price</p>
                  <div>
                    <p
                      onClick={() => {
                        lowpriceHandel();
                      }}
                      className={`${priceSort == "low" ? "active" : ""}`}
                    >
                      Low to High
                    </p>
                    <p
                      onClick={() => {
                        highpriceHandel();
                      }}
                      className={`${priceSort == "high" ? "active" : ""}`}
                    >
                      High to Low
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-end position-relative">
          <FilterAltIcon
            onClick={toggleFilter}
            className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 pack-title"
          />
           <p className="filters mb-0 f3">Sort By :</p>
          {showFilter && (
            <div className="filter-options position-absolute ">
              <p>Price</p>
              <div>
                <p onClick={toggleFilter} className="active">
                  Low to High
                </p>
                <p onClick={toggleFilter}>High to Low</p>
              </div>
            </div>
          )}
        </div> */}

        {categoryList?.map((item, ind) => {
          return (
            item?.products?.length > 0 && (
              <section id={item?.id} key={ind}>
                <h5 className="pack-title mb-0 mb-lg-3">{item?.title}</h5>
                <p className="parag disc w-90">{item?.description}</p>

                <div className="d-flex flex-wrap w-100 ac-je">
                  <div className="d-flex flex-wrap ps-4 w-100 ac-js">
                    {item?.products?.map((product, inx) => {
                      return (
                        <div
                          className="p-2 p-md-3 p-xxl-4 prod-list-box-tl"
                          key={inx}
                        >
                          <div className="prod-list-box">
                            <button
                              // onClick=>{showPopupHander,setViewId(product?.id)}
                              onClick={() => {
                                // showPopupHander();
                                getProductView(product?.id);
                              }}
                              className="cust-btn prod-list-box-img"
                            >
                              <img
                                src={
                                  product?.defaultImage
                                    ? product?.defaultImage
                                    : product1
                                }
                                alt=""
                              />
                            </button>
                            <div className="btm-prod">
                              <h3>{product?.name}</h3>
                              <div className="d-flex justify-content-between">
                                <p className=" f2">
                                  Starting at {+product?.for_25}
                                </p>
                                <button
                                  onClick={() => {
                                    // addProductHandler(item, ind, product, inx);
                                    if (cartData?.[product?.id]?.cart_id) {
                                      deleteCart(
                                        cartData?.[product?.id]?.cart_id,
                                        product?.id
                                      );
                                    } else {
                                      onClickProduct(product?.id);
                                    }

                                    // getAddToCart(product?.id, product?.for_100);
                                  }}
                                  className="plus"
                                >
                                  {tick?.includes(product?.id) ? (
                                    <CheckIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )
          );
        })}
      </div>
    </>
  );
};

export default FullProduct;
