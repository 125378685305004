// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { product } from "../../assets/img";
import { dashboardBanner, productDetailImg } from "../../redux/api/constants";
import { useNavigate } from "react-router-dom";
import { useDashboardOrderListMutation } from "../../redux/api/api";

const DashboardBanner = () => {
  const navigate = useNavigate();
  const [list, setlist] = useState([]);

  //RTK
  const [orderListApi] = useDashboardOrderListMutation();

  const bannerOderList = () => {
    orderListApi()
      .then((res) => {
        setlist(res?.data?.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    bannerOderList();
  }, []);
  return (
    <div className="dash-board-banner mt-3 my-sm-3">
      <div className="box">
        <Carousel indicators={true} className="position-relative w-100 h-100">
          {list?.map((item, ind) => {
            return (
              <Carousel.Item
                className=" justify-content-between"
                indicators={true}
                controls={false}
                interval={3000}
                key={ind}
              >
                <div className="caption-banner d-flex flex-row">
                  <div className="left-banner p-3">
                    <p className="bg-lt-design design fs-11 fs-md-12 f2 mb-2 px-2 py-1 rounded rounded-2 mt-4">
                      {item?.status}
                    </p>

                    <h3 className="text-dark f4 fs-16 fs-lg-30 fs-xl-40">
                      {item?.project_request_id}
                    </h3>
                    <h6 className="primary fs-14 fs-md-16 fs-lg-18 fs-xl-19 f4 mt-2 mt-lg-3 p-0 rounded rounded-2">
                      {item?.total_amount}
                    </h6>
                    <div className="btn-back" />
                    <button
                      // onClick={() => navigate("/proof-details")}
                      onClick={() => navigate("/my-product-detail")}
                      className="get-start-btn border-0"
                    >
                      View Proof
                    </button>
                  </div>
                  <div className="rit-banner">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      {...dashboardBanner}
                    >
                      {item?.project_request_details?.map((images, ind) => {
                        return (
                          <>
                            <div className="item">
                              <p className="text-dark f4 fs-18 fs-lg-20 fs-xl-22">
                                {images?.product?.name}
                              </p>
                              <img
                                src={
                                  images?.product?.defaultImage
                                    ? images?.product?.defaultImage
                                    : product
                                }
                                alt=""
                              />
                            </div>
                          </>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default DashboardBanner;
