import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const ProductColor = ({ showPopupHander, setShowDelete }) => {
  return (
    <div className="prod-color px-3  wi-100 w-sm-100 mt-4 mt-md-0">
      <div className="d-flex ac-js mb-3">
        <h5 className="f4 fs-17 fs-md-19 fs-lg-20 fs-xl-21 mb-0">
          Item Color:
        </h5>
        <div className="d-flex ac-js ms-2">
          <div
            className="cust-btn color-box1 d-flex ac-jc ms-2"
            style={{ background: "black" }}
          ></div>
          <p className="f4 text-primary fs-15 fs-md-17 fs-lg-18 fs-xl-19 mb-0">
            Color1
          </p>
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap ac-jb">
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "yellow" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            Yellow
          </p>
        </div>
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "aqua" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            Aqua Triblend
          </p>
        </div>
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "red" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            Solid Red Triblend
          </p>
        </div>
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "green" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            Sea Green Triblend
          </p>
        </div>
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "purple" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            Purple Triblend
          </p>
        </div>
        <div className="d-flex ac-js w-50 mb-3">
          <div
            className="cust-btn color-box1 d-flex ac-jc me-3"
            style={{ background: "blue" }}
          ></div>
          <p className="f3 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 mb-0">
            blue
          </p>
        </div>
      </div>
      <div className="line1" />
      {/* <div className="d-flex ac-jb w-100 mt-2">
        <p className="f3 primary1 fs-16 fs-md-18 fs-lg-19 mb-0">
          Dedication Details
        </p>
        <button
          className="f3 primary cust-btn fs-17 fs-md-19 fs-xl-21 mb-0">
          + Add
        </button>
      </div> */}
      <div>
        <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
          Decorative Method
        </p>
        <select className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2">
          <option>3D</option>
          <option>Fabric</option>
        </select>
      </div>
      <div>
        <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
          Logo Name
        </p>
        <input
          className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
          value={"Dream Believe Achieve"}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
            Logo Size
          </p>
          <input
            className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
            placeholder="L*B"
            value={"15*30"}
          />
        </div>
        <div>
          <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
            Logo Colour
          </p>
          <input
            className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
            value={"black"}
          />
        </div>
      </div>
      {/* <div className="d-flex w-100 ac-jb">
        <p className="f3 text-dark cust-btn fs-17 fs-md-18 fs-lg-19 mb-2 mt-2">
          Decoration D-8765
        </p>
        <ArrowForwardIosIcon className="text-dark fs-16 fs-md-18 fs-lg-19  mb-0" />
      </div>
      <p className="f2 primary1 fs-16 fs-md-18 fs-lg-19 mb-2 mb-md-3">
        Request, Comments, Changes?
      </p>
      <textarea
        className="text-dark fs-16 fs-md-18 fs-lg-19 mb-3 text-area-product p-2"
        name=""
        id=""
        cols="30"
        rows="4"
      ></textarea> */}
      <div className="line1" />
      <div className="d-flex ac-jb align-items-center">
        <button
          className="mt-3 border-0 bg-danger text-white py-2 rounded rounded-3 px-3 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18"
          onClick={showPopupHander}
        >
          Decline Design
        </button>
        <button
          className="mt-3 cust-btn f2 primary1 fs-15 fs-md-16 fs-lg-17 fs-xl-18 "
          onClick={() => setShowDelete(true)}
        >
          Product Delete
        </button>
      </div>
    </div>
  );
};
