// @ts-nocheck
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HeaderProduct from "../component/myProduct/HeaderProduct";
import ProductFilter from "../component/myProduct/ProductFilter";

const MyInventory = () => {
  return (
    <div className="my-product mx-auto w-90  w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
      <HeaderProduct
        title="Inventory"
        btnText="Reorder Products"
        thirdTitle="Out of Stock"
        secondTitle="In Stock"
      />
      <Outlet />
    </div>
  );
};

export default MyInventory;
