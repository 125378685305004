import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";

const AddressPopup = ({ poppupAddressToggle, showType }) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  return (
    <div className="modal-popup_2">
      <div onClick={poppupAddressToggle} className="back-close" />
      <div className="z_ind_cust center-content w-90 w-md-55 ">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            {showType} Contact
          </p>
          <div onClick={poppupAddressToggle} className="">
            <button className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-warning border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark mt-2">
              Cancel
            </button>
            <button className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-primar border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark">
              save
            </button>
          </div>
        </div>

        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" type="text" placeholder="First Name" />
            </div>
            <div className="box">
              <input className="" placeholder="Last Name" />
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" type="text" placeholder="Email ID" />
            </div>
            <div className="box">
              <input className="" type="text" placeholder="Address Line 1" />
            </div>
          </div>
        </div>

        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" type="text" placeholder="Address Line 2" />
            </div>
            <div className="box">
              <input className="" type="text" placeholder="Land Mark" />
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" placeholder="State" />
            </div>
            <div className="box">
              <input className="" type="text" placeholder="City" />
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" placeholder="Pincode" />
            </div>
            <div className="box">
              <input className="" type="text" placeholder="Phone Number" />
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input className="" placeholder="Alternate Phone Number" />
            </div>
            <div className="box">
              <input className="" type="text" placeholder="GST (optional)" />
            </div>
          </div>
        </div>
        <div className="continueBtn2 text-center">
          <button
            onClick={() => poppupAddressToggle()}
            className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-primar border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark mt-2"
          >
            Add Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressPopup;
