import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../../redux/api/constants";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon } from "../../../assets/img";
// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponent = ({
  varientsNew,
  showPopupHander,
  setSelectedIds,
  viewList,
  material,
  setMaterial,
  setSize,
  size,
  setStyle,
  style,
  selectedColorId,
  setSelectedColorId,
  selectColorId,
  setSelectColorId,
  setCartData,
  cartData,
  imagesid,
  setBigImg,
  bigImg,
  setCount,
  count,
  varients,
  calculations,
  deleteCart,
  cartBtn,
  deleteBtn,
  userCartID,
  qtyBtn,
}) => {
  // QTY HANDLER
  const increment = (id, type) => {
    let count_value = cartData?.[id]?.count
      ? +cartData?.[id]?.count
      : +cartData?.[id]?.count_value;

    let pack_value = cartData?.[id]?.pack;

    // console.log('pack_value', pack_value);

    if (type == "decrement") {
      if (+count_value > 1) {
        calculations(+count_value, id, pack_value, type);
      }
    } else {
      calculations(+count_value, id, pack_value, type);
    }
  };

  const setid = () => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(imagesid?.id);
    }
    setSelectedIds(temp);
  };

  useEffect(() => {
    setid();
  }, [count]);

  const handleColorClick = (colorname, imageUrl, ind, id, type, event) => {
    setSelectedColorId(colorname?.color?.name);
    setSelectColorId(colorname?.color?.id);

    if (type == "size") {
      setSize((val) => ({ ...val, [id]: event }));
      setSelectedColorId(selectedColorId);
      setSelectColorId(selectColorId);
    }

    if (type == "material") {
      setMaterial((val) => ({ ...val, [id]: event }));
      setSelectedColorId(selectedColorId);
      setSelectColorId(selectColorId);
    }

    if (type == "style") {
      setStyle((val) => ({ ...val, [id]: event }));
      setSelectedColorId(selectedColorId);
      setSelectColorId(selectColorId);
    }

    // setSelectedImage(imageUrl);
    setBigImg(ind);
    if (cartData?.[id]?.cart_id) {
      varientsNew(
        id,
        "",
        cartData?.[id]?.discount_value,
        type == "color" ? colorname?.color?.id : selectColorId,
        type == "color" ? colorname?.color?.name : selectedColorId,
        "",
        type == "size" ? event : size[id],
        type == "material" ? event : material[id],
        type == "style" ? event : style[id]
      );
    }
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      {viewList?.map((list, ind) => {
        return (
          <div
            className="center-content d-flex flex-column flex-lg-row"
            key={ind}
          >
            <button
              onClick={() => {
                showPopupHander();
                setCount(1);
                // setColorCount(1);
              }}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="Close" />
            </button>

            <div className="left-popup">
              <div
                className="position-relative"
                style={{ width: "200px" }}
              ></div>
              <div>
                <div className="imges">
                  <img
                    className="img_up_cont "
                    style={{
                      // height: "200px",
                      // width: "200px",
                      objectFit: "cover",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                    src={list?.images?.[bigImg]?.image_url}
                  />
                </div>

                <div
                  className="d-flex mt-4"
                  style={{ width: "400px", overflow: "scroll" }}
                >
                  {list?.images?.map((img, imgInd) => (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        key={imgInd}
                        id={img?.id}
                        class="add_img down_imgs d-flex ac-jc"
                        onClick={() => {
                          handleColorClick(img, "", imgInd, list?.id, "color");
                          setBigImg(imgInd);
                        }}
                      >
                        <img
                          src={img?.image_url}
                          style={{
                            padding: "10px",
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                            borderRadius: "15px",
                          }}
                          class="cp add_img object-fit-fill"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rit-pop">
              <h2 className="pe-lg-5 text-center text-lg-start">
                {list?.name}
              </h2>

              {list?.images?.length > 0 && (
                <h3 className="mt-3">Select Color</h3>
              )}

              {list?.images?.length > 0 && (
                <div className="d-flex ac-js">
                  {list?.images?.map((col, colInd) => (
                    <button
                      key={colInd}
                      style={{ background: col?.color?.hex_code }}
                      className="cust-btn color-box d-flex ac-jc"
                      onClick={() =>
                        handleColorClick(col, "", colInd, list?.id, "color")
                      }
                    >
                      {selectedColorId === col?.color?.name ? (
                        <DoneIcon className="text-white fs-25" />
                      ) : (
                        ""
                      )}
                    </button>
                  ))}
                </div>
              )}

              <div className="d-flex ac-jb p-2">
                {list?.sizes?.length > 0 && (
                  <div className="pe-2 text-center">
                    <div>
                      <label>
                        <h3>Sizes</h3>
                      </label>
                    </div>
                    <select
                      className="btn btn-light shadow-box1 px-3"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        handleColorClick(
                          { name: selectedColorId, id: selectColorId },
                          "",
                          bigImg,
                          list?.id,
                          "size",
                          e.target.value
                        );
                      }}
                      value={size[list?.id]}
                    >
                      <option value={""}>Select Size</option>
                      {list?.sizes?.map((size, ind) => {
                        return (
                          <option value={size?.value}>{size?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {list?.materials?.length > 0 && (
                  <div className="px-2 text-center">
                    <div>
                      <label>
                        <h3>Materials</h3>
                      </label>
                    </div>
                    <select
                      className="btn btn-light shadow-box1 px-3"
                      onChange={(e) => {
                        handleColorClick(
                          { name: selectedColorId, id: selectColorId },
                          "",
                          bigImg,
                          list?.id,
                          "material",
                          e.target.value
                        );
                      }}
                      value={material[list?.id]}
                    >
                      <option value={""}>Select Materials</option>
                      {list?.materials?.map((material, ind) => {
                        return (
                          <option value={material?.value}>
                            {material?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {list?.styles?.length > 0 && (
                  <div className="px-2 text-center">
                    <div>
                      <label>
                        <h3>Style</h3>
                      </label>
                    </div>

                    <select
                      className="btn btn-light shadow-box1 px-3"
                      onChange={(e) => {
                        handleColorClick(
                          { name: selectedColorId, id: selectColorId },
                          "",
                          bigImg,
                          list?.id,
                          "style",
                          e.target.value
                        );
                      }}
                      value={style[list?.id]}
                    >
                      <option value={""}>Select Style</option>
                      {list?.styles?.map((style, ind) => {
                        return (
                          <option value={style?.value}>{style?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jb pe-lg-5 mt-2 mt-lg-3">
                <div className="me-3 me-lg-0">
                  <h3>
                    Quantity
                    <span className="primary fs-19 me-4"> (max 10)</span>
                  </h3>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 me-2"
                      onClick={() => {
                        // decrement(list?.id);
                        increment(list?.id, "decrement");
                      }}
                      disabled={qtyBtn}
                    >
                      -
                    </button>
                    <h5 className=" fs-25 f5 px-2 mb-0">
                      {cartData?.[list?.id]?.count
                        ? cartData?.[list?.id]?.count
                        : cartData?.[list?.id]?.count_value}
                    </h5>
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 mx-2"
                      onClick={() => {
                        increment(list?.id, "increment");
                      }}
                      disabled={qtyBtn}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex ac-js">
                <h2 className="op-80">
                  ₹
                  {cartData?.[list?.id]?.packs_price
                    ? +cartData?.[list?.id]?.packs_price
                    : +cartData?.[list?.id]?.for_price}
                  /item{" "}
                </h2>
              </div>
              {/* {method == "final" ? (
                <div className="d-flex gap-2">
                  {" "}
                  <button
                    className="get-start-btn border-0 mb-2"
                    onClick={() => {
                      // getAddToCart(list?.id);
                      varients(list?.id);
                    }}
                  >
                    Update Pack
                  </button>
                  <button
                    className="get-start-btn border-0 mb-2"
                    onClick={() => {
                      // getAddToCart(list?.id);
                      deleteCart(userCartID, list?.id);
                    }}
                  >
                    Remove Pack
                  </button>
                </div>
              ) : ( */}
              <button
                className={`get-start-btn border-0 mb-2 ${
                  cartBtn ? "opacity-50" : deleteBtn ? "opacity-50" : ""
                }`}
                onClick={() => {
                  // getAddToCart(list?.id);
                  if (cartData?.[list?.id]?.cart_id) {
                    deleteCart(cartData?.[list?.id]?.cart_id, list?.id);
                  } else {
                    varients(list?.id);
                  }
                }}
                disabled={cartBtn ? true : deleteBtn ? true : false}
              >
                {cartData?.[list?.id]?.cart_id ? "Remove Pack" : "Add to Pack"}
              </button>
              {/* )} */}
              <h3 className="mt-3 mb-1">Description</h3>
              <p className="parag mb-4 px-3 ps-lg-0 text-center text-lg-start">
                {list?.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductPopupComponent;
